@use '../../../styles/_global-variables.scss' as global;

.col1 {
    width: calc(100% / 24 - 16px);
}
.col2 {
    width: calc(100% / 24 * 2 - 16px);
}
.col3 {
    width: calc(100% / 24 * 3 - 16px);
}
.col4 {
    width: calc(100% / 24 * 4 - 16px);
}
.col5 {
    width: calc(100% / 24 * 5 - 16px);
}
.col6 {
    width: calc(100% / 24 * 6 - 16px);
}
.col7 {
    width: calc(100% / 24 * 7 - 16px);
}
.col8 {
    width: calc(100% / 24 * 8 - 16px);
}
.col9 {
    width: calc(100% / 24 * 9 - 16px);
}
.col10 {
    width: calc(100% / 24 * 10 - 16px);
}
.col11 {
    width: calc(100% / 24 * 11 - 16px);
}
.col12 {
    width: calc(100% / 24 * 12 - 16px);
}
.col13 {
    width: calc(100% / 24 * 13 - 16px);
}
.col14 {
    width: calc(100% / 24 * 14 - 16px);
}
.col15 {
    width: calc(100% / 24 * 15 - 16px);
}
.col16 {
    width: calc(100% / 24 * 16 - 16px);
}
.col17 {
    width: calc(100% / 24 * 17 - 16px);
}
.col18 {
    width: calc(100% / 24 * 18 - 16px);
}
.col19 {
    width: calc(100% / 24 * 19 - 16px);
}
.col20 {
    width: calc(100% / 24 * 20 - 16px);
}
.col21 {
    width: calc(100% / 24 * 21 - 16px);
}
.col22 {
    width: calc(100% / 24 * 22 - 16px);
}
.col23 {
    width: calc(100% / 24 * 23 - 16px);
}
.col24 {
    width: 100%;
}

.col1,
.col2,
.col3,
.col4,
.col5,
.col6,
.col7,
.col8 {
    font-size: var(--tv-m3);
    line-height: 15px;
    p,
    ul {
        padding-bottom: 9px;
        font-size: var(--tv-m3);
        line-height: 15px;
    }
    h3 {
        font-size: var(--tv-m2);
    }
    li {
        line-height: 1.7;
    }
}

.alignBottom {
    align-self: end;
}

.center {
    margin-right: auto;
    margin-left: auto;
}

.marginBottom {
    margin-bottom: var(--gapRow);
}

@media #{global.$VP_ALL_MOBILE} {
    .directes {
        width: 100%;
    }
    .mobileCol24 {
        width: 100%;
        margin-top: 56px;
    }
    .mobileCol24:first-child {
        margin-top: 0;
    }
}
@media #{global.$VP_TP} {
    .col12.directes {
        width: 50%;
    }
}
@media #{global.$VP_BEYOND_TP} {
    .col12.directes {
        width: 50%;
    }
}
@media (min-width: 1920px) and (max-width: 2559px) {
    .fixedMosaic {
        padding: 0 132px;
    }
    .col12.directes {
        padding: 0 0 0 132px;
    }
    .col12.directes ~ .directes {
        padding: 0 132px 0 0;
    }
}
